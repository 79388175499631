<template>
    <div>
      <dashboard-page-title :showSearch="false" :showFilter="false" :showMainActions="false" >
        الطبيب الغير نشط
      </dashboard-page-title>
          <b-row>
            <b-col lg="8">
              <b-card class="mb-4">
                <template v-slot:header>
                    <h5 class="text-primary">البيانات الشخصية</h5>
                </template>
                <b-row>
                  <b-col md="2">
                    <img :src="doctorData.personal_picture" alt="logo of hospital" class="w-75 h-75 rounded-circle"/>
                  </b-col>
                  <b-col md="4" class="mb-3">
                    <input-form placeholder="الاسم بالكامل" label="الاسم بالكامل" v-model="doctorData.full_name" disabled name="title" validate="required"></input-form>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="6" class="mb-3">
                    <input-form placeholder="عنوان العيادة" label="عنوان العيادة" v-model="doctorData.clinic_address" disabled name="title" validate="required"></input-form>
                  </b-col>
                  <b-col lg="6" class="mb-3">
                    <input-form placeholder="البريد الالكترونى" label="البريد الالكترونى" v-model="doctorData.email" disabled name="title" validate="required"></input-form>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="6" class="mb-3">
                    <input-form placeholder="رقم الهاتف" label="رقم الهاتف" v-model="doctorData.primary_phone_number" disabled name="title" validate="required"></input-form>
                  </b-col>
                  <b-col lg="6" class="mb-3">
                    <input-form placeholder="رقم الهاتف الثانوى" label="رقم الهاتف الثانوى" v-model="doctorData.secondary_phone_number" disabled name="title" validate="required"></input-form>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="12" class="mb-3">
                    <textarea-form placeholder="الوصف" label="الوصف" v-model="doctorData.description" disabled name="title" validate="required"></textarea-form>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <div
                      class="setting-box-inner d-flex align-items-center gap_2"
                    >
                      <p class="setting-box-title">نسبة العمولة</p>
                      <div class="d-flex align-items-center gap_1">
                        <input-form
                          class="text-center m-0 matone-value"
                          placeholder=""
                          v-model="doctorData.fees"
                          disabled
                          name="clinic_examine"
                        />
                        <span class="small-text">%</span>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-card>
              <b-card class="mb-4" v-if="doctorData.prices">
                <template v-slot:header>
                  <h5 class="text-primary">الاسعار</h5>
                </template>
                <b-row>
                  <b-col sm="12">
                    <div class="setting-box-inner d-flex align-items-center gap_2" v-if="( doctorData.prices.consulting || doctorData.prices.clinic_examine || doctorData.prices.outdoor_examine )" >
                        <p class="setting-box-title">كشف العيادة</p>
                        <div class="d-flex align-items-center gap_1">
                        <input-form
                            class="text-center m-0 matone-value"
                            placeholder=""
                            v-model="doctorData.prices.clinic_examine"
                            disabled
                            name="clinic_examine" />
                        <span class="small-text">ريال سعودى</span>
                        </div>
                    </div>
                    <div class="setting-box-inner d-flex align-items-center gap_2" v-if="doctorData.prices.outdoor_examine">
                        <p class="setting-box-title">زيارة منزلية</p>
                        <div class="d-flex align-items-center gap_1">
                        <input-form
                            class="text-center m-0 matone-value"
                            placeholder=""
                            v-model="doctorData.prices.outdoor_examine"
                            disabled
                            name="clinic_examine" />
                        <span class="small-text">ريال سعودى</span>
                        </div>
                    </div>
                    <div class="setting-box-inner d-flex align-items-center gap_2" v-if="doctorData.prices.consulting">
                        <p class="setting-box-title">استشارة</p>
                        <div class="d-flex align-items-center gap_1">
                        <input-form
                            class="text-center m-0 matone-value"
                            placeholder=""
                            v-model="doctorData.prices.consulting"
                            disabled
                            name="clinic_examine" />
                        <span class="small-text">ريال سعودى</span>
                        </div>
                    </div>
                  </b-col>
                </b-row>
              </b-card>

              <!-- <b-card>
                <div class="">
                  <h5 class="text-primary">مواعيد العمل</h5>
                  <div class="p-4">
                    <div class="d-flex align-items-center gap_1 time-of-work-item flex-wrap" v-for="(timeOfWork, index) in doctorData.work_times" :key="index">
                      <div class="time-of-work-item-controls d-flex align-items-center gap_1">
                        <span class="time-of-work-item-controls-label font-size-20">{{timeOfWork.day}}</span>

                        <b-form-checkbox
                          :id="`time-of-work-item-controls-checkbox-${index}`"
                          v-model="timeOfWork.is_active"
                          :name="`time-of-work-item-controls-checkbox-${index}`"
                          class="d-flex align-items-center dashboard-custom-checkbox time-of-work-item-controls-checkbox"
                          :value="false"
                          :unchecked-value="true"
                          disabled
                          ariaDescribedby="item-checkbox-day"
                        >
                          مغلق
                        </b-form-checkbox>
                      </div>
                      <div v-if="timeOfWork.is_active" class="d-flex flex-column gap_1">
                        <div class="d-flex align-items-center gap_4  flex-wrap" v-for="(timeItem, idx) in timeOfWork.working_periods" :key="idx">
                          <div class="d-flex align-items-center gap_1">
                            <h6 class="setting-box-title time-of-work-item-label font-size-20">من</h6>
                            <flat-pickr
                              v-model="timeItem.from"
                              :config="config"
                              class="form-control time-of-work-item-controls-date"
                              placeholder="Select time"
                              name="time-of-work-item-controls-date"
                              disabled
                            />
                          </div>
                          <div class="d-flex align-items-center gap_1">
                            <h6 class="setting-box-title time-of-work-item-label font-size-20">الى</h6>
                            <flat-pickr
                              v-model="timeItem.to"
                              :config="config"
                              class="form-control time-of-work-item-controls-date"
                              placeholder="Select time"
                              name="time-of-work-item-controls-date-to"
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-card> -->
            </b-col>
            <b-col lg="4">
              <b-card v-for="item in doctorData.uploaded_documents" :key="item.id" class="mb-4">
                <div>
                  <span>{{ item.doc.title }}</span>
                  <hr />
                  <div class="d-flex gap-3" style="gap: 10px;">
                    <img :src="item.url" class="rounded-circle object-fit-cover" style="width: 80px; height: 40px;"/>
                    <b-button variant="outline-primary" id="show-btn" @click="showModal(item)">عرض الصورة</b-button>
                  </div>
                </div>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="mt-5">
            <b-col md="9" class="d-flex justify-content-center align-items-center" style="gap: 10px;">
              <div>
                <b-button variant="primary" class="px-5 py-3" id="show-btn" v-if="!loadingAccept" @click="acceptDoctor">قبول</b-button>
                <b-button v-else variant="primary" class="text-white px-5 py-3" disabled type="submit">
                  <b-spinner small type="grow"></b-spinner>
                  Loading...
                </b-button>
              </div>
              <div>
                <b-button variant="outline-danger" class="px-5 py-3" id="show-btn" v-if="!loadingReject" @click="showSuspensedModal">رفض</b-button>
                <b-button v-else variant="outline-danger" class="text-white px-5 py-3" disabled type="submit">
                  <b-spinner small type="grow"></b-spinner>
                  Loading...
                </b-button>
              </div>
            </b-col>
          </b-row>

      <b-modal ref="modal-photo" id="modal-photo" centered hide-footer>
        <template #modal-title>
          <div>
            {{ selectedPhoto.title }}
          </div>
        </template>
        <div class="image-container d-block text-center">
          <img :src="selectedPhoto.url" class="img-fluid"/>
        </div>
      </b-modal>

      <b-modal id="suspendedModal" size="lg" title="سبب ايقاف الدكتور" style="direction: rtl" centered hide-footer>
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(onSubmit)">
            <b-row class="d-flex flex-wrap">
              <b-col>
                <textarea-form placeholder="السبب" v-model="reason" label="سبب مختصر" name="reason" validate="required"></textarea-form>
              </b-col>
            </b-row>
          </form>
        </ValidationObserver>

          <div class="d-flex justify-content-center mt-2">
            <b-button v-if='saveButton' class="btn btn-primary float-right" variant="primary" disabled>
              <spinner-loading text = 'Loading' />
            </b-button>
            <b-button variant="primary" v-else class="w-50" @click="handleSuspendDoctor">Save</b-button>
          </div>
      </b-modal>
    </div>
  </template>
<script>
import { core } from '@/config/pluginInit'
import api from '../services/index'
import commonMixin from '@/mixins/commonMixin'
export default {
  components: { },
  mounted () {
    core.index()
  },
  mixins: [commonMixin],
  data () {
    return {
      reason: '',
      suspendedId: '',
      saveButton: false,
      selectedPhoto: {},
      loadingAccept: false,
      loadingReject: false,
      config: {
        enableTime: true,
        noCalendar: true,
        dateFormat: 'H:i',
        time_24hr: false,
        defaultDate: '14:30'
      },
      // loadingLogo: 0,
      doctorData: {
        id: '',
        title: ''
      }
    }
  },
  methods: {
    getDoctor () {
      api.getDoctor(this.$route.params.id).then(res => {
        this.doctorData = res.data
      })
    },
    acceptDoctor () {
      this.loadingAccept = true
      api.acceptDoctor(this.$route.params.id).then(res => {
        core.showSnackbar('success', res.data.message)
        this.$router.push({ name: 'inActiveDoctors' })
      }).finally(() => {
        this.loadingAccept = false
      })
    },
    showModal (item) {
      this.$refs['modal-photo'].show()
      this.selectedPhoto = { url: item.url, title: item.doc.title }
    },
    showSuspensedModal () {
      this.suspendedId = this.$route.params.id
      this.$bvModal.show('suspendedModal')
      // rejectDoctor(doctorData.id, 'rejected')
    },
    handleSuspendDoctor () {
      this.saveButton = true
      api.statusDoctor(this.suspendedId, 'rejected', { mail_body: this.reason }).then(res => {
        core.showSnackbar('success', 'تم  رفض الدكتور بنجاح')
        this.saveButton = false
        this.$bvModal.hide('suspendedModal')
        this.$router.push({ name: 'inActiveDoctors' })
      })
    },
    onSubmit () {}
  },
  created () {
    if (this.$route.params.id) {
      this.getDoctor()
      this.$root.$on('showSuspensedModal', this.showSuspensedModal)
    }
  }
}
</script>

<style>
  .vs__dropdown-toggle {
    background: #fff !important;
    border-radius: 10px !important;
  }
  .image-container {
    width: 100%;
  }
  .image-container img {
    width: 100%
  }
  .setting-box-title {
    min-width: 140px;
  }
  .setting-box-title {
    color: #3f414d !important;
    font-weight: normal !important
  }
  .setting-box-inner {
    margin-bottom: 30px
  }
</style>
